import React, {useState} from "react";
import TOTP_generateToken from "../Helper/TOTP.js";
import Card from "./Card";


function Wichtelsite(props) {

    const {changeUser, user, target, savedWishes, endpoint, token, config_id} = props

    const emptyWishInput = {title:"", description: "", link:"" }
    const [userWishes, setUserWishes] = useState(savedWishes)
    const [wishInput, setWishInput] = useState(emptyWishInput)
    const [addNewWish, setAddNewWish] = useState(false)
    const [showDeleteNotice, setShowDeleteNotice] = useState({show: false, id:""})
    const [showEditWish, setShowEditWish] = useState({show: false, id:""})
    const [showTargetTitle, setShowTargetTitle] = useState(false)

    const saveWishes = () => {
        const wishes = userWishes || []

        wishes.push(wishInput)

        const payload = {
            method: "addWish",
            id: user.userID,
            userName: user.value,
            wishes: wishes,
            config_id: config_id,
            token: TOTP_generateToken(token),
        };

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "*/*",
            },
            body: JSON.stringify(payload),
        });

        setAddNewWish(false)
        setUserWishes(wishes)
    };

    const deleteWish = () => {

        const id = showDeleteNotice.id;
        const wishes = userWishes.filter((wish, index) => id !== index)

        const payload = {
            method: "addWish",
            id: user.userID,
            userName: user.value,
            wishes: wishes,
            config_id: config_id,
            token: TOTP_generateToken(token),
        };

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "*/*",
            },
            body: JSON.stringify(payload),
        });
        
        setShowDeleteNotice({show: false, id: ""})
        setUserWishes(wishes)
        setWishInput(emptyWishInput)

    }

    const handleEdit = (edit) => {

        const wish = userWishes.find((wish, index) => index === edit.id)

        setWishInput(wish)

        setShowEditWish(edit)

    }
    const editWish = (event, id) => {

        const wishes = []
        userWishes.forEach((wish, index) => {
            if (index === id) {
                wish = wishInput
            }

            wishes.push(wish)
        })

        const payload = {
            method: "addWish",
            id: user.userID,
            userName: user.value,
            wishes: wishes,
            config_id: config_id,
            token: TOTP_generateToken(token),
        };

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "*/*",
            },
            body: JSON.stringify(payload),
        });

        setShowEditWish({show: false, id: ""})
        setUserWishes(wishes)
        setWishInput(emptyWishInput)

    }


    return <div className={"wichtelsite"}>
        <div className="users active">
            <ul>
                <li
                    className={"user " + user.value}
                    data-selection={JSON.stringify(user)}
                >
                    {user.label}
                </li>
                <li
                    className={"back"}
                    onClick={() => changeUser(false)}
                >
                    Zurück
                </li>
            </ul>
        </div>
        <div className={"christmas"}>
            <div className="christmas-scene">
                <div id="christmas">
                    <div className="flake large f-1"></div>
                    <div className="flake large f-2"></div>
                    <div className="flake large f-3"></div>
                    <div className="flake large f-4"></div>
                    <div className="flake large f-5"></div>
                    <div className="flake large f-6"></div>
                    <div className="flake large f-7"></div>
                    <div className="flake large f-8"></div>
                    <div className="flake large f-9"></div>
                    <div className="flake large f-10"></div>
                    <div className="flake large f-11"></div>
                    <div className="flake large f-12"></div>
                    <div className="flake large f-13"></div>
                    <div className="flake large f-14"></div>
                    <div className="flake large f-15"></div>
                    <div className="flake large f-16"></div>
                    <div className="flake large f-17"></div>
                    <div className="flake f-18"></div>
                    <div className="flake f-19"></div>
                    <div className="flake f-20"></div>
                    <div className="flake f-21"></div>
                    <div className="flake f-22"></div>
                    <div className="flake f-23"></div>
                    <div className="flake f-24"></div>
                    <div className="flake f-25"></div>
                    <div className="flake f-26"></div>
                    <div className="flake f-27"></div>
                    <div className="flake f-28"></div>
                    <div className="flake f-29"></div>
                    <div className="flake f-30"></div>
                    <div className="flake f-31"></div>
                    <div className="tree left">
                        <div className="snow"></div>
                    </div>
                    <div className="tree right">
                        <div className="snow"></div>
                    </div>
                    <div className="ground"></div>
                </div>
            </div>

        </div>
        <div className={"wishes"}>
            <div className="wishes-container">
                <div className="title">
                    <div className={"ribbon"}>
                        <div className="ribbon-content">
                            <span>Deine Wünsche</span>
                        </div>
                    </div>
                </div>
                <div className="user-wishes">
                    {userWishes.map((wish, index) => <Card key={wish.title.toLowerCase().trim().replace(" ", "-")} title={wish.title} id={index} deleteWish={setShowDeleteNotice} editWish={handleEdit} description={wish.description} link={wish.link}/>)}
                </div>
                <div className={"add-wish"} onClick={() => setAddNewWish(true)}><span className={"description"}>Wunsch hinzufügen</span></div>
            </div>
        </div>

        <div className={"target"}>
            <div className="target-container">
                <div className="target-header">
                    <div className="santa">
                        <div className="circles"></div>
                        <div className="snow"></div>
                        <div className="hat">
                            <div className="hat-end"></div>
                        </div>
                        <div className="face">
                            <div className="eyes"></div>
                            <div className="mouth"></div>
                        </div>
                        <div className="dirty-overflow">
                            <div className="body"></div>
                        </div>
                    </div>
                </div>

                <div className="title">
                    <div className={"ribbon"}>
                        <div className="ribbon-content">
                            <span>Dein Ziel</span>
                        </div>
                    </div>
                </div>
                <div className={!showTargetTitle ? "target-title" : "target-title show"} onClick={() => setShowTargetTitle(!showTargetTitle)}>
                    <h2>
                        <span>{target.label}</span>
                    </h2>
                </div>
                <div className="title">
                    <div className={"ribbon"}>
                        <div className="ribbon-content">
                            <span>Wünsche</span>
                        </div>
                    </div>
                </div>
                <div className={"target-wishes"}>
                    { showTargetTitle &&target.wishes.map((wish) => <Card key={wish.title.toLowerCase().trim().replace(" ", "-")}
                                                       title={wish.title} description={wish.description}
                                                       link={wish.link}/>)}
                </div>

            </div>
        </div>

        {addNewWish &&
            <div className={"add-new-wish"}>
                <div className={"card"}>
                    <div className="title">
                        <div className={"ribbon"}>
                            <div className="ribbon-content">
                                <span>Neuen Wunsch eintragen</span>
                            </div>
                        </div>
                    </div>
                    <label htmlFor={"wish-title"} >Titel</label>
                    <input id="wish-title" className={"wish-title"} type={"text"} onChange={(event) => setWishInput({...wishInput, title: event.currentTarget.value})}
                    ></input>
                    <label htmlFor={"wish-Description"}>Beschreibung (optional)</label>
                    <textarea id="wish-description" className={"wish-description"} rows={4} cols={50} onChange={(event) => setWishInput({...wishInput, description: event.currentTarget.value})}></textarea>
                    <label htmlFor={"wish-link"}>Link (optional)</label>
                    <input id="wish-link" className={"wish-link"} type={"text"} onChange={(event) => setWishInput({...wishInput, link: event.currentTarget.value})}></input>
                    <div className={"controls"}>
                        <div className={"button close"} onClick={() => setAddNewWish(false)}>Abbrechen</div>
                        <div className={"button save-wish"} onClick={(event) => saveWishes(event)}>Speichern</div>
                    </div>
                </div>


            </div>
        }

        {showEditWish.show &&
            <div className={"add-new-wish"}>
                <div className={"card"}>
                    <div className="title">
                        <div className={"ribbon"}>
                            <div className="ribbon-content">
                                <span>Wunsch bearbeiten</span>
                            </div>
                        </div>
                    </div>
                    <label htmlFor={"wish-title"} >Titel</label>
                    <input id="wish-title" className={"wish-title"} value={wishInput.title} onChange={(event) => setWishInput({...wishInput, title: event.currentTarget.value})}
                    ></input>
                    <label htmlFor={"wish-Description"}>Beschreibung (optional)</label>
                    <textarea id="wish-description" rows={4} cols={50} className={"wish-description"} value={wishInput.description} onChange={(event) => setWishInput({...wishInput, description: event.currentTarget.value})}></textarea>
                    <label htmlFor={"wish-link"}>Link (optional)</label>
                    <input id="wish-link" className={"wish-link"} value={wishInput.link} onChange={(event) => setWishInput({...wishInput, link: event.currentTarget.value})}></input>
                    <div className={"controls"}>
                        <div className={"button close"} onClick={() => setShowEditWish({show: false, id: ""})}>Abbrechen</div>
                        <div className={"button save-wish"} onClick={(event) => editWish(event, showEditWish.id)}>Speichern</div>
                    </div>
                </div>



            </div>
        }


        {showDeleteNotice.show && <div className={"delete-wish-notice"}>
            <div className={"delete-wish-notice-container"}>

                <span className="notice">Willst du den Wunsch wirklich löschen?</span>
                <div className={"controls"}>
                    <div className={"button close"} onClick={() => setShowDeleteNotice({show: false, id: ""})}>Abbrechen</div>
                    <div className={"button delete-wish"} onClick={(event) => deleteWish()}>Löschen</div>
                </div>
            </div>

        </div>}
            </div>

}


export default Wichtelsite;