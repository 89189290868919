import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


function Card(props) {

    const {title, description, link, id, editWish, deleteWish} = {...props}

    return <div className="card">
        <h2>{title}</h2>
        <div className="content">
            {description && <p className={"description"}>{description}</p>}
            {link && <a className={"link"} href={link} target={"_blank"}>Link</a>}
        </div>
        { typeof id === "number" && <div className={"button delete"} onClick={() => deleteWish({show: true, id: id})}><FontAwesomeIcon icon={solid('xmark')} /></div> }
        { typeof id === "number" && <div className={"button edit"} onClick={() => editWish({show: true, id: id})}><FontAwesomeIcon icon={solid('pen-to-square')} /></div> }

    </div>
}

export default Card;

