import React, {useState} from "react";

function Loginscreen(props) {
    const {changeUser, selectableUsers, family, delay, activated} = {...props};

    const [isActive, setIsActive] = useState(activated)

    const handleChange = (event) => {
        const selection = JSON.parse(event.currentTarget.dataset.selection);
        changeUser(selection);
    };

    if (delay) {
        setTimeout(()=> {
            setIsActive(true)
        }, 1000)
    }

    let state = "";

    if (isActive && delay) state = "show"

    if (isActive && !delay) state = "active"



    return (
        <div className={`login ${state}`}>
            <div className="ribbon-container">
                <div className={`ribbon ${state === "active"? "show" : state}`}>
                    <div className="medallion"></div>
                    <div className="ribbon-1">
                        <span className="inner">
                            <span className="fadeLeft">Die große</span>
                        </span>
                    </div>
                    <div className="ribbon-2">
                        <span className="inner">
                            <span className="fadeRight">Wichtelei</span>
                        </span>
                    </div>
                    <div className="ribbon-3">
                        <span className="inner">
                            <span className="fadeLeft">{family}</span>
                        </span>
                    </div>
                    <div className="ball fadeIn">
                        <span className="ball-text">
                            <strong>2024</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className={`users ${state}`}>
                <ul>
                    {selectableUsers.map(user => <li
                        key={user.value}
                        className={"user " + user.value}
                        onClick={handleChange}
                        data-selection={JSON.stringify(user)}
                    >
                        {user.label}
                    </li>)}
                </ul>
            </div>
            <div className={`tree ${state}`}>
                <div className="tree__row">
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
                <div className="tree__row">
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                    <div className="tree__node"></div>
                </div>
            </div>
    </div>
    );
}

export default Loginscreen;
