import CryptoJS from "crypto-js";

const TOTP_generateToken = (secret, ttl = 3600) => {
  // 1 hour in seconds = 3600
  const test = CryptoJS.HmacSHA256(TOTP_stamp(ttl), secret).toString(
    CryptoJS.enc.Hex
  );
  return test;
};

const TOTP_stamp = (ttl) => {
  const timestamp = Math.floor(Date.now() / 1000);

  const test = Math.floor(timestamp / ttl).toString();

  return test;
};

export default TOTP_generateToken;
